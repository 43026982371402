import React from "react";
import '../styles/Pantalla.css';

const Pantalla = ({ input }) => (
    <div className="input">
        {input}
    </div>
)


export default Pantalla;
